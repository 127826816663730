<template>
  <el-dialog :visible.sync="dialogVisible" title="选择学员" top="1%" width="60%" :center="true" :before-close="tableBack">
    <div class="CourseTableBox">
      <section class="CourseTable">
        <div class="operationControl">
          <div class="searchbox">
            <div title="招生任务名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 120px;">招生任务名称:</span>
              <el-input v-model="searchForm.projectName" clearable size="small" placeholder="请输入招生任务名称"></el-input>
            </div>
            <!-- <div title="招生任务名称" class="searchboxItem ci-full">
              <span class="itemLabel">招生任务名称:</span>
              <el-select v-model="searchForm.projectId" filterable clearable remote size="small" placeholder="请至少输入两字搜索"
                :remote-method="getProjectList">
                <el-option v-for="item in projectList" :key="item.projectId" :label="item.projectName"
                  :value="item.projectId">
                </el-option>
              </el-select>
            </div> -->
          </div>
        </div>
        <div class="operationControl">
          <div class="searchbox">
            <div title="选择考试时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 120px;">选择考试时间:</span>
              <el-checkbox-group v-model="activityExamIds">
                <el-checkbox v-for="(item, index) in activityExamIdsList" :key="index" :label="item.activityExamId">{{ item.subjectName }}：{{item.startTime }} - {{ item.endTime }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div>
              <el-button class="bgc-bv" @click="search()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" stripe :header-cell-style="tableHeader" @selection-change="handleSelectionChange"
              :row-key="(row) => row.projectId">
              <el-table-column type="selection" :reserve-selection="true" width="55">
              </el-table-column>
              <el-table-column label="招生任务名称" align="center" show-overflow-tooltip prop="projectName" minWidth="100" />
              <el-table-column label="招生截止时间" align="center" show-overflow-tooltip prop="endDate" minWidth="160" />
              <el-table-column label="报名人数" align="center" show-overflow-tooltip prop="applyNum" minWidth="100" />
              <el-table-column label="审核通过人数" align="center" show-overflow-tooltip prop="passNum" minWidth="100" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </section>

    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="tableBack">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
    <!--    <PaperShow ref="PaperShow" :modal="false" />-->
  </el-dialog>
</template>

<script>
import Empty from "@/components/Empty4.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import PaperShow from "@/views/resourse/popup/PaperShow";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";

export default {
  name: "SelectStudents2",
  components: {
    Empty,
    PageNum,
    // PaperShow,
    // tree,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      dialogVisible: false,
      multipleSelection: [],
      activityId: '',
      searchForm: {
        projectName: ''
      },
      // projectId: '',
      // ctProjectId: '',
      // EnrollmentList: [],
      selectionKeys: [],
      // 培训下拉数据
      projectList: [],
      activityExamIds: [], // 考试时间多选数据绑定
      activityExamIdsList: [], // 考试时间多选数据
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    // 培训搜索方法
    // getProjectList(query){
    //   if(query.trim().length<2){
    //     return;
    //   }
    //   this.$post("/biz/ct/project/ctProjectListNoPage",{
    //     projectName:query
    //   }).then(res=>{
    //     this.projectList=res.data||[];
    //   });
    // },
    // 获取 - 考试时间多选数据
    getCheckbox() {
      this.$post("/biz/examination_affairs/activity/exam/list", {
        activityId: this.activityId,
      }, 3000, true, 6).then(res => {
        this.activityExamIdsList = res.data || [];
      })
        .catch(() => {
          return;
        });
    },
    // 多选选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 招生信息
    // getEnrollmentList(query) {
    //   if (query.trim().length >= 2) {
    //     this.$post("/biz/ct/project/ctProjectListNoPage", { projectName: query })
    //         .then((res) => {
    //           if (res.status == 0) {
    //             this.EnrollmentList = res.data || [];
    //           }
    //         })
    //         .catch(() => {
    //           return;
    //         });
    //   } else {
    //     this.EnrollmentList = [];
    //   }
    // },
    // tinit() {
    //   // this.getCourseOriginList();
    //   this.init();
    //   this.getTableHeight();
    // },
    search() {
      // if( !this.searchForm.projectName ){
      //   this.$message.error("请输入招生任务名称查询！");
      // }else{
      // this.searchForm.projectName = this.projectList.filter(item => item.projectId == this.searchForm.projectId)[0].projectName
      this.$refs.multipleTable.clearSelection()
      console.log(this.multipleSelection)
      this.getData()
      // }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        activityId: this.activityId,
        // projectName: this.searchForm.projectName
      };
      if (this.searchForm.projectName) {
        params.projectName = this.searchForm.projectName
      }

      this.doFetch({
        url: "/biz/examination_affairs/user/selectProjectList",
        params,
        pageNum,
      }, true, 6);
      // setTimeout(()=> {
      //   this.selectionKeys.forEach(key => {
      //     this.tableData.forEach(row => {
      //       if (row.userId== key.userId) {
      //         this.$refs.multipleTable.toggleRowSelection(row, true);
      //       }
      //     })
      //   })
      // },1000)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 660 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      let _this = this
      if (!this.multipleSelection.length) {
        this.$message.error("请至少选择一个培训！");
        return
      } else if (!this.activityExamIds.length) {
        this.$message.error("请至少选择一个考试时间！");
        return
      } else {
        let projectIds = this.multipleSelection.map(item => item.projectId);
        let params = {
          activityId: this.activityId,
          projectIds: projectIds,
          activityExamIds: this.activityExamIds
        }
        this.$post("/biz/examination_affairs/user/chooseProject", params, 3000, true, 6)
          .then((res) => {
            if (res.status == 0) {
              _this.$message({
                message: "成功添加",
                type: "success",
              });
              _this.tableBack()
            } else {
              _this.$message({
                message: ret.message,
                type: "error",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    tableBack() {
      this.dialogVisible = false;
      // this.ctProjectId = ''
      this.tableData = []
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      this.projectList = [];
      this.selectionKeys = [];

      this.$parent.getData()

    },
    showPopUp(activityId) {
      this.activityId = activityId
      this.tableData = []
      this.apiData = {
        total: 0,
        size: 10,
        current: 1
      }
      this.projectList = [];
      this.selectionKeys = [];
      this.searchForm = {
        projectId: '',
        projectName: ''
      }
      this.dialogVisible = true;
      this.getData()
      this.getCheckbox()
      this.$refs.multipleTable.clearSelection()
    },
  },
};
</script>

<style lang="less" scoped>
.operationControl {
  .searchbox {
    /deep/ .searchboxItem {
      .el-checkbox-group {
        .el-checkbox {
          .el-checkbox__input {
            .el-checkbox__inner {
              width: 14px;
              min-width: 14px;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}

.CourseTable {
  width: 100%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;

  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}

.testTime {
  // margin-left: 180px;
  padding-top: 20px;

  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }

  .testFomation {
    display: block;
    width: 300px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
