<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">考试列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">选择学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="searchForm.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchForm.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div title="是否补考" class="searchboxItem ci-full">
              <span class="itemLabel">是否补考:</span>
              <el-select v-model="searchForm.purchased" placeholder="是否补考" size="small" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="Setexamination()">选择学员</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="importStudentInfo()">导入学员</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="importStudentPic()">导入学员证件照片</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" prop="userName" show-overflow-tooltip min-width="120px" />
              <el-table-column label="性别" align="center" prop="sex" show-overflow-tooltip min-width="100px">
              </el-table-column>
              <el-table-column label="身份证" align="center" prop="idcard" show-overflow-tooltip min-width="160px" />
              <el-table-column label="手机号" align="center" prop="mobile" show-overflow-tooltip min-width="120px" />
              <el-table-column label="工作单位" align="center" prop="workUnit" show-overflow-tooltip min-width="260px" />
              <el-table-column label="考试信息" align="center" show-overflow-tooltip min-width="260px"><template
                  slot-scope="{ row }">
                <template v-if="row.examList.length > 0">
                  <div v-for="item in row.examList" :key="item.activityExamId">
                    {{
                      $setDictionary("EA_EXAM_SUBJECT_CODE", item.subjectCode)
                    }}
                    : {{ item.startTime }} - {{ item.endTime }}
                  </div>
                </template>
                <template v-else> 无 </template>
              </template>
              </el-table-column>
              <el-table-column label="是否补考" align="center" prop="purchased" show-overflow-tooltip min-width="100px">
                <template slot-scope="{ row }">
                  {{ row.purchased ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remarks" show-overflow-tooltip min-width="260px" />
              <el-table-column label="操作" align="center" width="150px" fixed="right">

                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="small"
                    @click="adjustingExamTimeOpen(scope.row)">调整考试时间</el-button>
                  <el-tooltip class="item" effect="dark" content="非导入学员，不支持修改手机号" placement="top-start"
                    v-if="scope.row.userId">
                    <el-button type="text" style="padding:0px 5px" size="small"
                      :disabled="scope.row.userId">更改手机号</el-button>
                  </el-tooltip>
                  <el-button type="text" style="padding:0px 5px" size="small" @click="studentPhoneNumberOpen(scope.row)"
                    v-else> 更改手机号
                  </el-button>
                  <el-button type="text" style="padding:0px 5px" size="small" @click="delUser(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        <SelectStudents2 ref="SelectStudents2" @getData="getData" />
      </div>
    </div>
    <!--鉴定机构通用导入-->
    <!-- businessType  导入业务类型标识 [ BUSINESS_TYPE ]-->
    <!-- importType  导入类型 学员-->
    <!-- unit  单位 学员-人-->
    <!--fileType  EXCEL  ZIP-->
    <accreditationAgencyImport ref="accreditationAgencyImport" @eventBus="accreditationAgencyImportBack"
      businessType="EXAMINATION_AFFAIRS_USER_IMPORT" :activityExamBOS="activityExamIdsC" importType="学员" unit="人"
      fileType="EXCEL">
      <template v-slot:header>
        <h4 style="margin: 10px 0;">请选择考试时间:</h4>
        <el-checkbox-group v-model="activityExamIds" @change="changeActivityExamIds">
          <el-checkbox v-for="(item, index) in activityExamIdsList" :key="index" :label="item.activityExamId">{{
            item.subjectName }}：{{ item.startTime }} - {{ item.endTime }}</el-checkbox>
        </el-checkbox-group>
      </template>
    </accreditationAgencyImport>
    <accreditationAgencyImport ref="accreditationAgencyImport2" @eventBus="accreditationAgencyImportBack"
      businessType="EXAMINATION_AFFAIRS_PICTURE_IMPORT" importType="证件照片" desc="
      1.上传后缀为.zip格式的压缩包文件，将证件照片直接添加到压缩包内，不要先放在文件夹再添加到压缩包内；
      2.上传压缩包最大限制100M，如文件超过100M，请分成多个压缩包分批导入；
      3.压缩包内文件为图片格式，以身份证号命名，例如：6101011900001010151.jpg" unit="个" fileType="ZIP" />
    <!-- 更改学员手机号 - 弹窗 -->
    <el-dialog title="更改手机号" :visible.sync="studentPhoneNumber.dialogVisible" width="500px"
      :before-close="studentPhoneNumberClose">
      <el-form :model="studentPhoneNumber" :rules="studentPhoneNumberRules" ref="studentPhoneNumber" label-width="100px"
        size="small" class="demo-ruleForm">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="studentPhoneNumber.mobile" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="studentPhoneNumberClose">取 消</el-button>
        <el-button size="small" class="bgc-bv" @click="studentPhoneNumberDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 调整考试时间 - 弹窗 -->
    <el-dialog title="调整考试时间" :visible.sync="adjustingExamTimeData.dialogVisible" width="500px"
      :before-close="adjustingExamTimeClose">
      <el-form :model="adjustingExamTimeData" :rules="adjustingExamTimeDataRules" ref="adjustingExamTimeData"
        label-width="100px" size="small" class="demo-ruleForm">
        <el-form-item label="姓名：">
          {{ adjustingExamTimeData.userName }}
        </el-form-item>
        <el-form-item label="是否补考：">
          {{ adjustingExamTimeData.purchased ? '是' : '否' }}
        </el-form-item>
        <el-form-item label="考试时间：" prop="activityExamIds">
          <el-checkbox-group v-model="adjustingExamTimeData.activityExamIds">
            <el-checkbox v-for="(item, index) in activityExamIdsList" :key="index" :label="item.activityExamId">{{
            item.subjectName }}：{{ item.startTime }} - {{ item.endTime }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="adjustingExamTimeClose">取 消</el-button>
        <el-button size="small" class="bgc-bv" @click="adjustingExamTimeDetermine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import SelectStudents2 from "@/views/appraisalInstitution/exam/popup/SelectStudents";
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";
export default {
  name: "chooseStudentList",
  components: {
    accreditationAgencyImport,
    Empty,
    PageNum,
    SelectStudents2,
  },
  mixins: [List],
  data() {
    return {
      // 考务id
      activityId: '',
      // 检索数据
      searchForm: {
        userName: "", //姓名
        mobile: "", //手机号
        purchased: "", //是否补考
      },
      // 考试时间多选数据绑定
      activityExamIds: [],
      // 考试时间多选数据绑定 - 传给组件的集合
      activityExamIdsC: [],
      // 考试时间多选数据
      activityExamIdsList: [],
      // 更改学员手机号 - 弹窗 - 数据
      studentPhoneNumber: {
        dialogVisible: false, // 弹框状态
        userCacheId: "", // 考务活动人员主键
        mobile: "", // 手机号
      },
      // 更改学员手机号 - 弹窗 - 数据校验
      studentPhoneNumberRules: {
        mobile: [{ required: true, validator: this.$validatePhone, trigger: "blur" }],
      },
      // 调整考试时间 - 弹窗 - 数据
      adjustingExamTimeData: {
        dialogVisible: false, // 弹框状态\
        activityId: "", // 考务活动id
        userCacheId: "", // 考务活动人员id
        userName: "", // 姓名
        purchased: false, // 是否补考
        activityExamIds: [], // 选择的考务活动时间的考试主键id集合
      },
      // 调整考试时间 - 弹窗 - 数据
      adjustingExamTimeDataRules: {
        activityExamIds: [{ required: true, message: "请选择考试时间", trigger: "change" }],
      },
    };
  },
  created() {
    this.activityId = this.$route.query.activityId;
    this.getActivityExamIdsList();
  },
  computed: {},
  methods: {
    // 获取 - 考试时间多选数据
    getActivityExamIdsList() {
      this.$post("/biz/examination_affairs/activity/exam/list", {
        activityId: this.activityId,
      }, 3000, true, 6).then(res => {
        this.activityExamIdsList = res.data || [];
      })
        .catch(() => {
          return;
        });
    },
    // 导入学员
    importStudentInfo() {
      this.$refs.accreditationAgencyImport.showPopup(this.activityId)
    },
    // 后台想要的集合是[{}],所以要这么写
    changeActivityExamIds() {
      this.activityExamIdsC = [];
      for (let i = 0; i < this.activityExamIds.length; i++) {
        for (let n = 0; n < this.activityExamIdsList.length; n++) {
          if (this.activityExamIds[i] == this.activityExamIdsList[n].activityExamId) {
            this.activityExamIdsC.push(this.activityExamIdsList[n])
          }
        }
      }
    },
    // 导入学员证件照片
    importStudentPic() {
      this.$refs.accreditationAgencyImport2.showPopup(this.activityId)
    },
    // 组件回调
    accreditationAgencyImportBack() {
      this.getData(-1)
    },
    // 选择学员弹窗打开
    Setexamination() {
      this.$refs.SelectStudents2.showPopUp(this.$route.query.activityId);
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        activityId: this.$route.query.activityId
      };
      if (this.searchForm.userName) {
        params.userName = this.searchForm.userName;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      params.purchased = this.searchForm.purchased;
      this.doFetch({
        url: "/biz/examination_affairs/user/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 调整考试时间 - 弹窗 - 打开
    adjustingExamTimeOpen(row) {
      this.$confirm('如果学员已经分配考场或下载准考证，考试时间变更后，请重新分配考场并通知学员重新下载','提示')
        .then(_ => {
          this.adjustingExamTimeData.activityId = this.activityId;
          this.adjustingExamTimeData.userCacheId = row?.userCacheId ?? "";
          this.adjustingExamTimeData.userName = row?.userName ?? "";
          this.adjustingExamTimeData.purchased = row?.purchased ?? false;
          this.$post("/biz/examination_affairs/user/exam/list", {
            activityId: this.activityId,
            userCacheId: row.userCacheId,
          }, 3000, true, 6)
            .then((res) => {
              let arr = [];
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].joinState) {
                  arr.push(res.data[i].activityExamId);
                }
              }
              this.adjustingExamTimeData.activityExamIds = arr;
              this.adjustingExamTimeData.dialogVisible = true;
            })
            .catch((err) => {
              return;
            });
        })
        .catch(_ => { });

    },
    // 调整考试时间 - 弹窗 - 确定
    adjustingExamTimeDetermine() {
      this.$refs['adjustingExamTimeData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/examination_affairs/user/exam/update", this.adjustingExamTimeData, 3000, true, 6)
            .then((res) => {
              this.getData(this.pageNum);
              this.adjustingExamTimeClose();
              this.$message({
                message: "操作成功",
                type: "success",
              });
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 调整考试时间 - 弹窗 - 关闭
    adjustingExamTimeClose(row) {
      this.adjustingExamTimeData = this.$options.data().adjustingExamTimeData;
    },
    // 更改学员手机号 - 弹窗 - 打开
    studentPhoneNumberOpen(row) {
      this.studentPhoneNumber.userCacheId = row?.userCacheId ?? "";
      this.studentPhoneNumber.mobile = row?.mobile ?? "";
      this.studentPhoneNumber.dialogVisible = true;
    },
    // 更改学员手机号 - 弹窗 - 确定 非导入学员，不支持修改手机号
    studentPhoneNumberDetermine() {
      this.$refs['studentPhoneNumber'].validate((valid) => {
        if (valid) {
          this.$post("/biz/examination_affairs/user/mobile/modify", {
            userCacheId: this.studentPhoneNumber.userCacheId,
            mobile: this.studentPhoneNumber.mobile,
          }, 3000, true, 6)
            .then((res) => {
              this.getData(this.pageNum);
              this.studentPhoneNumberClose();
              this.$message({
                message: "操作成功",
                type: "success",
              });
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 更改学员手机号 - 弹窗 - 关闭
    studentPhoneNumberClose() {
      this.studentPhoneNumber = this.$options.data().studentPhoneNumber;
    },
    //删除考务人员及其分配的座位等信息
    delUser(row) {
      this.$confirm("确认删除学员信息吗，这将导致学员基本信息、考场安排、准考证等信息一起删除，请在删除后重新安排考场，重新下载助考资料，并通知学员下载新准考证。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$post("/biz/examination_affairs/user/del", {
          userCacheId: row.userCacheId,
        }, 3000, true, 6)
          .then((res) => {
            this.getData(this.pageNum);
            this.$message({
              message: "操作成功",
              type: "success",
            });
          })
          .catch((err) => {
            return;
          });
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>

<style lang="less"></style>
